// Compatible with @progress/kendo-theme-bootstrap v.5.8.0

$tb-kendo-border-radius: 0.25rem;
$tb-dark-theme: false;
$tb-primary: #4574b1;
$tb-secondary: #595b57;
$tb-success: #6f9365;
$tb-info: #b9aa69;
$tb-warning: #c06b34;
$tb-danger: #dc3545;
$tb-body-bg: #ffffff;
$tb-body-text: #000000;
$tb-subtle-text: #828580;
$tb-disabled-text: #d1d0cd;
$tb-component-bg: $tb-body-bg;
$tb-component-text: #595b57; //$tb-body-text;
$tb-base-bg: #ffffff;
$tb-base-text: #595b57; //$tb-body-text;
$tb-hovered-bg: #f1f1f0;
$tb-hovered-text: #4574b1; //$tb-base-text;
$tb-selected-bg: #f1f1f0; //$tb-primary;
$tb-selected-text: #595b57;
$tb-kendo-button-bg: #c06b34;
$tb-kendo-button-text: #ffffff;
$tb-link-text: $tb-primary;
$tb-link-hover-text: #b9aa69;
$tb-series-a: $tb-primary;
$tb-series-b: #6f9365;
$tb-series-c: #c06b34;
$tb-series-d: #f0ad4e;
$tb-series-e: #595b57;
$tb-series-f: #a2a19a;

//// Original settings generated by https://themebuilderapp.telerik.com/login
// $tb-kendo-border-radius: 0.375rem;
// $tb-dark-theme: false;
// $tb-primary: #0d6efd;
// $tb-secondary: #6c757d;
// $tb-success: #198754;
// $tb-info: #0dcaf0;
// $tb-warning: #ffc107;
// $tb-danger: #dc3545;
// $tb-body-bg: #ffffff;
// $tb-body-text: #212529;
// $tb-subtle-text: #6c757d;
// $tb-disabled-text: #adb5bd;
// $tb-component-bg: $tb-body-bg;
// $tb-component-text: $tb-body-text;
// $tb-base-bg: #f8f9fa;
// $tb-base-text: $tb-body-text;
// $tb-hovered-bg: #e9ecef;
// $tb-hovered-text: $tb-base-text;
// $tb-selected-bg: $tb-primary;
// $tb-selected-text: #ffffff;
// $tb-kendo-button-bg: #e4e7eb;
// $tb-kendo-button-text: #212529;
// $tb-link-text: $tb-primary;
// $tb-link-hover-text: #0a58ca;
// $tb-series-a: $tb-primary;
// $tb-series-b: #6f42c1;
// $tb-series-c: #20c997;
// $tb-series-d: #198754;
// $tb-series-e: #ffc107;
// $tb-series-f: #dc3545;

$kendo-border-radius: $tb-kendo-border-radius;
$dark-theme: $tb-dark-theme;
$primary: $tb-primary;
$secondary: $tb-secondary;
$success: $tb-success;
$info: $tb-info;
$warning: $tb-warning;
$danger: $tb-danger;
$body-bg: $tb-body-bg;
$body-text: $tb-body-text;
$subtle-text: $tb-subtle-text;
$disabled-text: $tb-disabled-text;
$component-bg: $tb-component-bg;
$component-text: $tb-component-text;
$base-bg: $tb-base-bg;
$base-text: $tb-base-text;
$hovered-bg: $tb-hovered-bg;
$hovered-text: $tb-hovered-text;
$selected-bg: $tb-selected-bg;
$selected-text: $tb-selected-text;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$link-text: $tb-link-text;
$link-hover-text: $tb-link-hover-text;
$series-a: $tb-series-a;
$series-b: $tb-series-b;
$series-c: $tb-series-c;
$series-d: $tb-series-d;
$series-e: $tb-series-e;
$series-f: $tb-series-f;
$font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size: 1rem;
$font-weight-normal: 400;
$line-height: 1.5;

$enable-gradients: true;

$tb-typography: (
    default-typography: (font-family: 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        font-size: 1rem,
        font-weight: 400,
        line-height: 1.5,
    ),
);

@mixin typography-classes ($typography) {

    @each $selector,
    $property in $typography {
        &-#{$selector} {

            @each $propName,
            $propValue in $property {
                #{$propName}: $propValue;
            }
        }
    }
}