/////////////////////////////////////////////////////
// CDR palette.
/////////////////////////////////////////////////////

// Blue
$palette-blue-10: #E3EAF3;
$palette-blue-20: #C6D5E8;
$palette-blue-30: #A0B9D8;
$palette-blue-40: #4574B1;

// Green
$palette-green-10: #EAEFE8;
$palette-green-20: #D4DFD1;
$palette-green-30: #B7C9B2;
$palette-green-40: #6F9365;

// Burnt Orange
$palette-burntorange-10: #F6E9E1;
$palette-burntorange-20: #EBD3C3;
$palette-burntorange-30: #DFB69B;
$palette-burntorange-40: #C06B34;

// Gold
$palette-gold-10: #F0EDE0;
$palette-gold-20: #E6DFC9;
$palette-gold-30: #D9CFAB;
$palette-gold-40: #B9AA69;

// Dark Gray
$palette-darkgray-10: #E6E7E6;
$palette-darkgray-20: #CDCECD;
$palette-darkgray-30: #ACADAB;
$palette-darkgray-40: #595B57;

// Gray
$palette-gray-10: #F1F1F0;
$palette-gray-20: #E3E3E1;
$palette-gray-30: #D1D0CD;
$palette-gray-40: #A2A19A;

// White and Black
$palette-white: #ffffff;
$palette-black: #000000;

/////////////////////////////////////////////////////
// Common variables.
/////////////////////////////////////////////////////

$font-weight-heavy: 800;
$font-weight-medium: 500;
$font-weight-light: 300;

$primary-appbar-color: $palette-white;
$primary-appbar-background-color: $palette-gray-40;

$nav-bar-background-color: $palette-blue-40;
$nav-bar-reverse-background-color: $palette-blue-30;

$kendo-control-percent: 90%;

$gutter-unit: 8px;